<template>
    <div class="container">
        <table class="table">
            <thead>
                <tr>
                    <th colspan="2">
                        <h2>
                            {{ countf }} Equipements
                        </h2>
                    </th>
                    <th colspan="2">
                        <p>
                            <v-select label="libelle" :options="fonctions" v-model="selectedFonction" @input="setFonction"></v-select>
                        </p>
                    </th>
                    <th colspan="2">
                        <p>
                            <v-select label="libelle" :options="regulateurs" v-model="selectedRegulateur" @input="setRegulateur"></v-select>
                        </p>
                    </th>
                </tr>
            </thead>

            <thead>
                <tr>
                    <th scope="col"><i class="fa fa-lightbulb-o"></i> Feu</th>
                    <th scope="col"><i class="fa fa-road"></i> Fonction</th>
                    <th scope="col"><i class="fa fa-bolt"></i> Régulateur</th>
                    <th scope="col"><i class="fa fa-lightbulb-o"></i> Type</th>
                    <th scope="col"><i class="fa fa-arrow-left"></i> Candela</th>
                    <th scope="col"><i class="fa fa-paint-brush"></i> Couleur</th>
                    <th scope="col"><i class="fa fa-arrow-right"></i> Candela</th>
                </tr>
            </thead>
            <tbody>
                <tr :key="feu.idfeu" v-for="feu in feux">
                    <td>{{ feu.nomfeu }}</td>
                    <td>{{ feu.nomfonction }}</td>
                    <td>{{ feu.nomregulateur }}</td>
                    <td>{{ feu.typefeu }}</td>
                    <td><span v-if="feu.candela > 0" :class="couleur(feu)">{{ feu.candela }} ({{action(feu)}})</span>
                    </td>
                    <td>
                        <span v-if="feu.strcode != null"><img :src="svg(feu.strcode)" /></span>
                    </td>
                    <td><span v-if="feu.candela2 > 0" :class="couleur2(feu)">{{ feu.candela2 }}
                            ({{action2(feu)}})</span></td>
                </tr>
            </tbody>

        </table>
        <br /><br />
    </div>
</template>
  
<script>
import store from "../store";
import { getPrefix, selectApi } from "../lib/api.js";
import { getPath } from "../lib/svg.js";
import { calcAttenuation } from "../lib/fct.js";

export default {
    name: "Feux",
    store: store,
    data() {
        return {
            countf: 0,
            feux: [],
            fonctions: [],
            regulateurs: [],
            selectedRegulateur: null,
            selectedFonction: null
        };
    },
    filters: {
        sdt: function (dt) {
            if (!dt) return "";
            return (
                dt.substring(8, 10) +
                "/" +
                dt.substring(5, 7) +
                "/" +
                dt.substring(0, 4)
            );
        },
    },
    methods: {
        svg(code) {
            let path = getPath(code, 0, false);
            return path;
        },
        setFonction() {
          this.refresh(this.selectedFonction,this.selectedRegulateur)
        },
        setRegulateur() {
            this.refresh(this.selectedFonction,this.selectedRegulateur)
        },       
        action(mesure) {
            if (mesure == null) return "";
            let cd = mesure.candela;
            if (cd < mesure.cdminimal1) {
                return "HS";
            } else if (cd > mesure.cdmaintenance1) {
                return "Ok";
            } else {
                return "Action";
            }
        },
        action2(mesure) {
            if (mesure == null) return "";
            let cd = mesure.candela2;
            if (cd < mesure.cdminimal2) {
                return "HS";
            } else if (cd > mesure.cdmaintenance2) {
                return "Ok";
            } else {
                return "Action";
            }
        },
        couleur(mesure) {
            if (mesure == null) return "green";
            let cd = mesure.candela;
            if (cd < mesure.cdminimal1) {
                return "red";
            } else if (cd > mesure.cdmaintenance1) {
                return "green";
            } else {
                return "orange";
            }
        },
        couleur2(mesure) {
            if (mesure == null) return "green";
            let cd = mesure.candela2;
            if (cd < mesure.cdminimal2) {
                return "red";
            } else if (cd > mesure.cdmaintenance2) {
                return "green";
            } else {
                return "orange";
            }
        },
        refresh(fct,reg) {

            if (fct == null || fct == undefined) {
                fct = 0
            } else {
                if (fct.id == undefined) {fct = 0} else {fct = fct.id}
            }
            if (reg == null || reg == undefined) {
                reg = 0
            } else {
                if (reg.id == undefined) {reg = 0} else {reg = reg.id}
            }
            let id = store.state.piste;

            // console.log(fct,reg,id)

            let sql =
                "SELECT f.id AS idfeu,f.nom as nomfeu,eq.libelle as nomfonction" +
                ",(SELECT IF(mesure=1,candela,-1) FROM mesurefeu WHERE idfeu=f.id ORDER BY Dt DESC LIMIT 1) AS candela" +
                ",(SELECT IF(mesure2,candela2,-1) FROM mesurefeu WHERE idfeu=f.id  ORDER BY Dt DESC LIMIT 1) AS candela2" +
                ",(SELECT LEFT(Dt,10) FROM mesurefeu WHERE idfeu=f.id  ORDER BY Dt DESC LIMIT 1) AS Dt10" +
                ",fp.cdnominal,fp.cdminimal,fp.cdnominal - (fp.cdminimal / 2) AS cdmaintenance" +
                ",fp.cdnominal AS cdnominal2,fp.cdminimal AS cdminimal2,fp.cdnominal - (fp.cdminimal / 2) AS cdmaintenance2,c.strcode" +
                ",f.idfonction,tf.libelle AS typefeu,f.serigraphie,IF(tf.strcode2 IS NULL,'FEU.png',tf.strcode2) AS imgfeu,f.statut,f.angle,reg.nom as nomregulateur" +
                " FROM feu AS f" +
                " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
                " LEFT JOIN cgbcommun.tableref AS eq ON eq.id=f.idfonction" +
                " LEFT JOIN cgbcommun.tableref AS tf ON tf.id=f.idtype" +
                " LEFT JOIN fonctionpiste AS fp ON fp.idequipement=eq.id" +
                " LEFT JOIN regulateur as reg ON f.idregulateur=reg.id" +
                " WHERE f.idpiste=" + id
                if (fct != 0) sql += " AND f.idfonction=" + fct
                if (reg != 0) sql += " AND f.idregulateur=" + reg
                sql += " ORDER BY eq.libelle ASC,f.nom ASC"

            // console.log(sql)

            selectApi(this, sql).then(
                (response) => {
                    this.countf = response.data.count;
                    this.feux = response.data.result;

                    this.feux.forEach((feu) => {


                        let attenuation = calcAttenuation(feu.idfonction,0,feu.strcode)
                        feu.cdminimal1 = feu.cdminimal * attenuation;
                        feu.cdnominal1 = feu.cdnominal * attenuation;
                        feu.cdmaintenance1 = feu.cdmaintenance * attenuation;

                        attenuation = calcAttenuation(feu.idfonction,1,feu.strcode)
                        feu.cdminimal2 = feu.cdminimal * attenuation;
                        feu.cdnominal2 = feu.cdnominal * attenuation;
                        feu.cdmaintenance2 = feu.cdmaintenance * attenuation;

                    });
                },
                (response) => {
                }
            );

        },
        getFonctions() {
            let id = store.state.piste;

            let sql = "(SELECT 0 AS id,'Tous' AS libelle) UNION (" +
                "(SELECT eq.id,eq.libelle FROM feu AS f LEFT JOIN cgbcommun.tableref AS eq ON eq.id=f.idfonction WHERE f.idpiste=" + id + 
                " AND eq.id IS NOT NULL GROUP BY f.idfonction) ORDER BY libelle)"

            selectApi(this, sql).then(
                (response) => {
                    this.fonctions = response.data.result;
                    //console.log(this.fonctions);
                },
                (response) => {
                }
            );
        },

        getRegulateurs() {
            let id = store.state.piste;

            let sql = "(SELECT 0 AS id,'Tous' AS libelle) UNION (" +
                "(SELECT reg.id,reg.nom as libelle FROM regulateur as reg WHERE reg.idpiste=" + id + ") ORDER BY nom)"
                console.log(sql)
            selectApi(this, sql).then(
                (response) => {
                    this.regulateurs = response.data.result;
                    //console.log(this.regulateurs);
                },
                (response) => {
                }
            );
        },

    },
    mounted() {
        if (store.state.typeUser == 0) {
            this.$router.push({ name: "login" });
        }
        this.getFonctions();
        this.getRegulateurs();
        this.refresh(0);
    },
};
</script>
  
<style lang="scss" scoped>
@import "src/assets/scss/design.scss";

ul {
    list-style-type: none;
}

.red {
    color: red;
}

.green {
    color: green;
}

.orange {
    color: orange;
}
</style>